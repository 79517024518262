import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SvgCloseIcon from "../../../../assets/images/vector/components/common/cn-modalClose.svg";
import "./index.scss";

const DealsRDModalWindow = ({ isOpened, handlerClick }) => {
  useEffect(() => {
    const htmlBlock = document.getElementsByTagName("html")[0];
    if (!isOpened) {
      htmlBlock.style.overflow = "auto";
    } else {
      htmlBlock.style.overflow = "hidden";
    }
  }, [isOpened]);

  if (!isOpened) {
    return null;
  }

  return (
    <div className="PgRD-DealsRDModalWindow">
      <div className="PgRD-DealsRDModalWindow__wrapper" />
      <div className="PgRD-DealsRDModalWindow__content">
        <div className="PgRD-DealsRDModalWindow__title">Thank you!</div>
        <div className="PgRD-DealsRDModalWindow-text PgRD-DealsRDModalWindow-text__rowOne">
          The Sample Report has been sent to your email
        </div>
        <div className="PgRD-DealsRDModalWindow-text">
          If you don't receive it within 10 minutes, please let us know at{" "}
          <span>support@amzsc.amzgb.com</span>
        </div>
        <div
          className="PgRD-DealsRDModalWindow__close"
          onClick={() => handlerClick()}
        >
          <img src={SvgCloseIcon} alt="x" />
        </div>
      </div>
    </div>
  );
};

DealsRDModalWindow.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  handlerClick: PropTypes.func.isRequired,
};

export default DealsRDModalWindow;
